<template>
    <Layout>
        <PageHeader :title="state.title" :items="state.items" />
        <div class="card">
            <div class="card-header d-flex align-items-center">
                <h4 class="card-title mb-0 flex-grow-1 fs-16 fw-700">{{ $t('facility.facility-info-upload') }}</h4>
                <b-dropdown variant="dark" toggle-class="d-flex align-items-center min-h-38">
                    <template #button-content>
                        <div class="d-flex align-items-center" style="column-gap: 10px">
                            <span>
                                {{ state.dropdownText }}
                            </span>
                        </div>

                    </template>
                    <b-dropdown-item href="#" @click="() => handleSelectImportType('facility')">{{
                        $t('facility.facility-information')
                    }}</b-dropdown-item>
                    <b-dropdown-item href="#" @click="() => handleSelectImportType('room')">{{ $t('t-sauna-room')
                    }}</b-dropdown-item>
                </b-dropdown>
            </div>
            <div class="card-body shadow-lg">
                <div class="position-relative m-4">
                    <div class="progress" style="height: 1px;">
                        <div class="progress-bar" role="progressbar" :style="{ width: percent }" aria-valuenow="0"
                            aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <b-button size="sm" :variant="state.step === 1 ? 'dark' : 'light'"
                        class="position-absolute top-0 start-0 translate-middle rounded-pill flex justify-content-center align-items-center"
                        style="width: 2rem; height:2rem;">
                        <i class="bx bx-cloud-upload fs-20"></i>
                    </b-button>
                    <b-button size="sm" :variant="state.step === 2 ? 'dark' : 'light'"
                        class="position-absolute top-0 start-50 translate-middle rounded-pill flex justify-content-center align-items-center"
                        style="width: 2rem; height:2rem;">
                        <i class="mdi mdi-view-list-outline fs-22"></i>
                    </b-button>
                    <b-button size="sm" :variant="state.step === 3 ? 'dark' : 'light'"
                        class="position-absolute top-0 start-100 translate-middle rounded-pill flex justify-content-center align-items-center"
                        style="width: 2rem; height:2rem;">
                        <i class="bx bx-checkbox-checked fs-24"></i>
                    </b-button>
                </div>
                <div v-if="state.step === 1" style="min-height: 300px;">
                    <DropZone @drop.prevent="drop" @selectedFile="selectedFile" accept=".xlsx, .xls, .csv"
                        :allowedExtensions="['text/csv', 'application/csv',
                            'text/comma-separated-values', 'application/excel',
                            'application/vnd.msexcel', 'text/anytext', 'application/vnd. ms-excel',
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']" />
                    <div class="hstack gap-2 justify-content-end mt-2">
                        <button @click="backToList" class="btn btn-soft-success">
                            {{ $t("t-back-to-list") }}
                        </button>
                    </div>
                </div>
                <div v-if="state.step === 2">
                    <div id="preview" class="overflow-scroll table-responsive" style="min-height: 300px;"></div>
                    <div class="hstack gap-2 justify-content-end">
                        <button type="submit" @click="submit" class="btn btn-primary" :disabled="state.disabled">
                            {{ $t("t-submit-update") }}
                        </button>
                        <button type="button" @click="updateStep(1)" class="btn btn-soft-success">
                            {{ $t("t-cancel") }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
  
<script>
import { reactive, watch, ref, computed } from "vue";
import * as xlsx from 'xlsx';
import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header";
import DropZone from "@/components/widgets/dropZone";
import i18n from '@/i18n';
import Swal from "sweetalert2";
import { uploadService } from "@/services";
import router from "@/router";
import Papa from 'papaparse';


export default {
    components: {
        Layout,
        PageHeader,
        DropZone
    },

    setup() {
        const state = reactive({
            title: '',
            items: [
                {
                    text: 'SETTING',
                    href: "/room/list",
                },
                {
                    text: i18n.global.t('facility.facility-info-upload'),
                    active: true,
                },
            ],
            step: 1,
            progress: 0,
            uploadType: 'facility',
            dropdownText: i18n.global.t('facility.facility-information'),
            disabled: false,

        })

        let files = ref([]);
        let dropzoneFile = ref("");

        /**
         * Update progress step
         * @param {Number} step 
         */
        const updateStep = (step) => {
            state.step = step
            if(step === 1) state.disabled = false
            if (step === 2) state.progress = 50
            else if (step === 3) state.progress = 100
            else state.progress = 0
        }

        const drop = (e) => {
            dropzoneFile.value = e.dataTransfer.files[0];
            files.value.push(dropzoneFile.value);
            processPreviewFile(dropzoneFile.value);
            updateStep(2)
        };
        /**
         * Handle after upload
         * @param {File} file 
         */
        const selectedFile = async (file) => {

            dropzoneFile.value = file;
            files.value.push(dropzoneFile.value);
            processPreviewFile(file);
            updateStep(2);
        };

        /**
         * Process submit
         */
        const submit = async () => {
            state.disabled = true;
            try {
                let key = 'facility.facility-info-upload-success';
                if (state.uploadType === 'facility') {
                    await uploadService.importFacility(dropzoneFile.value);
                } else {
                    key = 'facility.room-upload-success'
                    await uploadService.importRoom(dropzoneFile.value);
                }
                updateStep(3);
                await Swal.fire({
                    title: "",
                    text: i18n.global.t(key),
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500,
                });
                updateStep(1);
            } catch (e) {
                updateStep(1);
                console.log(e)
                await Swal.fire({
                    title: "",
                    text: 'Error',
                    icon: "error",
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        }

        /**
         * Preview file content as a table
         * @param {File} file 
         */
        const processPreviewFile = (file) => {
            console.log(file)
            const reader = new FileReader();
            reader.onload = () => {
                const fileData = reader.result;
                const parsedData = Papa.parse(fileData);
                const { data: pData } = parsedData;
                const worksheet = xlsx.utils.aoa_to_sheet(pData);
                const workbook = xlsx.utils.book_new();
                xlsx.utils.book_append_sheet(workbook, worksheet, 'Preview');

                const html = xlsx.utils.sheet_to_html(worksheet)
                    .replace(/<table>/g, '<table class="table table-bordered table-hover align-middle table-nowrap fs-12">');

                const block = document.getElementById("preview");
                block.innerHTML = html;

            }

            reader.readAsText(file);

        };

        /**
         * 
         * @param {String} uploadType 
         */
        const handleSelectImportType = (uploadType) => {
            dropzoneFile.value = '';
            files.value = [];
            updateStep(1);
            state.uploadType = uploadType;
            state.dropdownText = i18n.global.t(uploadType === 'facility' ? 'facility.facility-information' : 't-sauna-room')
        }

        const backToList = () => {
            router.replace({ name: 'admin-facility-list' })
        }

        const percent = computed(() => {
            return `${state.progress}%`
        });

        watch(
            () => [...files.value],
            (currentValue) => {
                return currentValue;
            }
        );

        return {
            state,
            updateStep,
            drop,
            selectedFile,
            percent,
            submit,
            handleSelectImportType,
            backToList
        }
    },
}
</script>
  